<template>
  <base-layout-two page-title="New Engagement" page-default-back-link="/engagements">

    <section class="padding-8">

      <base-card title="">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <h3>At Home Engagements cannot be made via the ERP at this time</h3>
              <p>However, you can help clients make an engagement
                by creating a custom checkout link with the following information:</p>

              <p><i>- Day sessions are 4 hours and can be scheduled in the morning, afternoon or
                  evening.</i></p>
              <p><i>- Night sessions are 8 hours from 10pm-6am.</i></p>
            </ion-col>

            <!-- Day Sessions -->
            <ion-col size="12" size-lg="6">
              <base-input label-text="Day Sessions*">
                <ion-input name="sessions_day" v-model="engagement.sessions_day" type="number" required></ion-input>
              </base-input>
            </ion-col>

            <!-- Night Sessions -->
            <ion-col size="12" size-lg="6">
              <base-input label-text="Night Sessions *">
                <ion-input name="sessions_night" v-model="engagement.sessions_night" type="number" required></ion-input>
              </base-input>
            </ion-col>

            <!-- Price Final -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Subtotal (excluding taxes & fees)">
                <p>
                  ${{ formatCurrency(engagement.price_final) }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Taxes -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Taxes & fees">
                <p>
                  ${{ formatCurrency(engagement.price_taxes) }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Total -->
            <ion-col size="12" size-lg="6">
              <base-field-display label-text="Total">
                <p>
                  ${{ formatCurrency(engagement.price_total) }}
                </p>
              </base-field-display>
            </ion-col>

            <!-- Total -->
            <ion-col size="12">
              <base-field-display label-text="Share this Custom Checkout Link">
                <a :href="engagementCheckoutLink" target="_blank">{{ engagementCheckoutLink }}</a>
              </base-field-display>
            </ion-col>
          </ion-row>
        </ion-grid>
      </base-card>
    </section>
  </base-layout-two>
</template>

<script>
import {
  IonGrid,
  IonRow,
  IonCol,
  IonInput,
} from "@ionic/vue";
import { mapActions } from "vuex";
import { formatCurrency } from "@/util/helpers";

export default {
  components: {
    IonGrid,
    IonRow,
    IonCol,
    IonInput,
  },

  data() {
    return {
      formatCurrency,
      engagement: {
        sessions_day: 0,
        sessions_night: 0,
        price_final: 0,
        price_taxes: 0,
        price_total: 0,
        price_deposit: 0
      },
    };
  },

  computed: {
    engagementCheckoutLink() {
      return `${process.env.VUE_APP_GUEST_PORTAL}/athome/finish/custom?sessions_day=${this.engagement.sessions_day}&sessions_night=${this.engagement.sessions_night}`;
    }
  },

  watch: {
    // Update the Price
    "engagement.sessions_day": function () {
      let price_final = (this.engagement.sessions_day * 4 * 45) + (this.engagement.sessions_night * 8 * 50)
      let price_taxes = price_final * 0.08875
      let price_total = price_final + price_taxes

      this.engagement.price_final = price_final.toFixed(2)
      this.engagement.price_taxes = price_taxes.toFixed(2)
      this.engagement.price_total = price_total.toFixed(2)
      this.engagement.price_deposit = 150
    },

    // Update the Price
    "engagement.sessions_night": function () {
      let price_final = (this.engagement.sessions_day * 4 * 50) + (this.engagement.sessions_night * 8 * 55)
      let price_taxes = price_final * 0.08875
      let price_total = price_final + price_taxes

      this.engagement.price_final = price_final.toFixed(2)
      this.engagement.price_taxes = price_taxes.toFixed(2)
      this.engagement.price_total = price_total.toFixed(2)
      this.engagement.price_deposit = 150
    },
  },

  async ionViewWillEnter() {
    this.engagement.sessions_day = 4
    this.engagement.sessions_night = 2
  },

  methods: {
    ...mapActions(["loadToast", "setShowLoading"]),
  },
};
</script>

<style scoped></style>